<template>
  <div class="page">
    <loader :visible="loading" />
    <main-menu
      :title="$t('create_a_new_folder')"
      :backButton="{ icon: 'icon-arrow-left2', clickCallback: onBackClicked }"
    ></main-menu>
    <div class="form">
      <form action="#" @submit="createFolder">
        <div class="input-container">
          <label for="name">{{ $t("name") }}</label>
          <input id="name" v-model="name" type="text" />
        </div>
        <div class="input-container">
          <label for="password">{{ $t("password_optional") }}</label>
          <input id="password" v-model="password" type="password" />
        </div>
        <custom-button
          :button="{
            icon: 'icon-floppy-disk',
            text: $t('save'),
            clickCallback: createFolder,
          }"
        ></custom-button>
        <input
          type="submit"
          style="position: absolute; left: -99999px"
          tabindex="-1"
        />
      </form>
    </div>
  </div>
</template>

<script>
import MainMenu from "../components/MainMenu.vue";
import Loader from "../components/Loader.vue";
import CustomButton from "../components/CustomButton.vue";
import api from "../api";

export default {
  name: "CreateFolder",
  components: { MainMenu, Loader, CustomButton },
  metaInfo() {
    return {
      title: this.$i18n.t("create_a_new_folder"),
    };
  },
  data() {
    return {
      loading: false,
      name: null,
      password: null,
    };
  },
  computed: {},
  methods: {
    onBackClicked() {
      this.$router.push({ name: "home" });
    },
    async createFolder(e) {
      this.loading = true;
      e.preventDefault();
      try {
        const response = await api(this.$root.jwtToken).post(`/folder`, {
          name: this.name,
        });
        this.$router.push({
          name: "folder",
          params: { id: response.data.folder.id },
          query: { token: response.data.folder.token },
        });
      } catch (error) {
        alert(error.response.data.message);
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less">
@import "../styles/form.less";
</style>
